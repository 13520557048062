/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

function stickyMenu(MenuID, anchorSide) {
    var waypoint = new Waypoint({
        element: document.getElementById(MenuID),
        handler: function (direction) {
            var $element = $(this.element);

            if ($element.outerHeight() < $(window).height() || $element.hasClass('fixed-menu')) {
                if (direction === 'down') {

                    if (anchorSide === 'right') {
                        var offsetRight = $(window).width() - ($element.offset().left + $element.outerWidth());
                        $element.css('right', offsetRight);
                        $element.css('width', $element.outerWidth());
                    }

                    $element.addClass('fixed-menu');
                } else {
                    $element.removeClass('fixed-menu');
                    $element.css('right', "");
                    $element.css('width', "");
                }
            }
        }
    });
}

function addButtonEvent() {
    $('[data-role="add"]').on('click', function (e) {

        e.preventDefault();

        $target = $($(this).data('target')).first();
        $clone = $target.clone().insertAfter($target);

        $clone.find("input, textarea, select").val("");
        $clone.find('[data-role="delete"]').removeClass("invisible");
        $clone.find('[required]').removeAttr('required');

        deleteButtonEvent();
    });
}

function deleteButtonEvent() {
    $('[data-role="delete"]').on('click', function (e) {
        e.preventDefault();

        $this = $(this);

        $modal = $("#modalSuppr");

        $modal.find('.element-name').text($this.data('element'));
        $modal.find('[data-valid]').on('click', function () {
            $modal.modal('hide');
            $target = $this.parents($this.data('target')).first();
            $target.remove();
        });
        $modal.modal('show');
    });
}

function baseName(str) {
    var base = str.substring(str.lastIndexOf('/') + 1);
    base = base.substring(base.lastIndexOf('\\') + 1);
    return base;
}

function truncateFileName(n, len) {
    var ext = n.substring(n.lastIndexOf(".") + 1, n.length).toLowerCase();
    var filename = n.replace('.' + ext, '');
    if (filename.length <= len) {
        return n;
    }
    filename = filename.substr(0, len) + (n.length > len ? '[...]' : '');
    return filename + '.' + ext;
}

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Oralia = {
        // All pages
        'common': {
            init: function () {

                var fileLabelContent = null;

                $('input[type="file"]').on('change', function () {
                    var $fileInput = $(this);
                    var $fileLabel = $fileInput.siblings('.file-label');
                    if(fileLabelContent === null) {
                        fileLabelContent = $fileLabel.text();
                    }
                    var files = $fileInput.get(0).files;
                    var nbFiles = files.length;
                    var filename = baseName($fileInput.val());

                    if (nbFiles === 0) {
                        $fileLabel.text(fileLabelContent);
                    } else {
                        $fileLabel.html(truncateFileName(filename, 10) + ' <span class="badge badge-danger">' + nbFiles + '</span>');
                    }
                });

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Select account
        'select_account': {
            init: function () {
                var options = {
                    valueNames: ['details', 'illustration']
                };

                new List('accounts', options);
            },
            finalize: function () {

            }
        },
        'page_template': {
            init: function () {
                stickyMenu('acces-rapide', 'right');
                stickyMenu('nav-primary', null);

                var $menuToggle = $('.menu-toggle');

                $menuToggle.click(function () {
                    var active = false;
                    var $this = $(this);

                    if ($this.hasClass('active')) {
                        active = true;
                    }

                    $menuToggle.removeClass('non-active');
                    $menuToggle.removeClass('active');

                    if (!active) {
                        $menuToggle.addClass('non-active');
                        $this.removeClass('non-active');
                        $this.addClass('active');
                    }
                });
            },
            finalize: function () {

            }
        },
        'profil': {
            init: function () {

                addButtonEvent();
                deleteButtonEvent();

                //$('#profil-form').validate();

            },
            finalize: function () {

            }
        },
        'signaler': {
            init: function () {
                var $signalerBtn = $('[data-toggle="signaler"]');
                var $signalerDetails = $('#details-signalement');
                var $categorieInput = $('[data-id="signaler"]');

                $signalerBtn.on('click', function () {
                    var $this = $(this);
                    $signalerBtn.removeClass('active');
                    $this.addClass('active');
                    $signalerDetails.slideDown();
                    $categorieInput.val($this.data('value'));
                    $this.data('value');
                });
            },
            finalize: function () {

            }
        },
        'etat_depenses': {
            init: function () {
                $('#etats-depenses-tabs').find('a').click(function (e) {
                    e.preventDefault();
                    $(this).tab('show');
                });
            },
            finalize: function () {

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Oralia;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
